<template>
  <div class="footer">

    <section class="about">
      <div>
        <div class="cotainer">
          <div class="left-container">
            <div class="item" @click="jumpTo('documentation')">
              <div class="title">
                Documentation
              </div>
            </div>
            <div class="item item-right" @click="jumpTo('twitter')">
              <div class="image-twitter">
                <img src="../assets/twitter.svg" /> 
              </div>
              <div class="title">
                Twitter
              </div>
            </div>
            <div class="item item-right" @click="jumpTo('medium')">
              <div class="image-medium">
                <img src="../assets/medium.svg" /> 
              </div>
              <div class="title">
                Medium
              </div>
            </div>
            <div class="item item-right" @click="jumpTo('telegram')">
              <div class="image-telegram">
                <img src="../assets/telegram.svg" /> 
              </div>
              <div class="title">
                Telegram
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ext">
      <div class="copyright">
        <div class="image">
          <img src="../assets/logo2.svg" /> 
        </div>
        <div class="desc"> a TMM DEX for interest-bearing token</div>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: 'footerBar',
  data() {
    let lang = window.localStorage.getItem('lang');
    return {
      lang: lang,
      langItems: [
        {
          id: 'en',
          desc: 'En'
        }, 
        {
          id: 'cn',
          desc: '中文'
        }
      ]
    }
  },
  methods: {

    jumpTo(target) {
      if (target == 'documentation') {
        window.open('https://docs.wrapfi.io/');
      } else if (target == 'twitter') {
        window.open('https://twitter.com/Wrapped_Finance');
      } else if (target == 'medium') {
        window.open('https://medium.com/@wrapped-finance');
      } else if (target == 'telegram') {
        window.open('https://t.me/wrapped_finance');
      }
    }
  }
};
</script>

<style lang="scss">
.footer {
  // width: 1100px;
  // margin: 0 auto;
  background: #252525;
  padding-left: 80px;

  .about {
    padding-top: 20px;
    padding-bottom: 22px;

    .left-container {
      display: flex;

      .item {
        cursor: pointer;
        color: #fff;

        .title {
          font-size: 21px;
          font-weight: bold;
        }
      }
      
      .item-right {
        display: flex;
        margin-left: 60px;

        .image-twitter {
          width: 20px;
          height: 17px;
          img {
            width: 100%;
            height: 100%;
          }
          margin-top: 3px;
          margin-right: 5px;
        }

        .image-medium {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
          }
          margin-top: 1px;
          margin-right: 5px;
        }

        .image-telegram {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
          }
          margin-top: 1.5px;
          margin-right: 5px;
        }
      }
    }
  }

  .ext {
    padding-bottom: 30px;
    padding-right: 25px;
    
    .copyright {
      display: flex;

      .image {
        width: 128px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
        }
        margin-left: auto;
      }

      .desc {
        margin-left: 10px;
        color: #757575;
        font-size: 20px;
      }
    }

  }
}
  
</style>
