<template>
  <div class="home">

    <section class="head">
      <div>
        <div class="container">
          <div class="container-left">
            <div class="title">
              <span class="title11" @click="jumpTo('fair-launch')">Fair Launch Program</span> to Farm 10,000,000 WRA Tokens
            </div>
            <div class="operations">
              <div class="phase1">
                <div class="phase-btn phase1-btn" @click="jumpTo('fair-launch-phase1')">
                  Phase 1
                </div>
                <!-- <div class="live">LIVE</div> -->
              </div>
              <div class="phase2">
                <div class="phase-btn phase2-btn" @click="jumpTo('fair-launch-phase2')">
                  Phase2
                </div>
                <div class="live">LIVE</div>
              </div>
            </div>
          </div>

          <div class="container-right">
            <div class="image">
              <img src="../assets/drop-farm.svg" /> 
            </div>
          </div>

        </div>

      </div>
    </section>
    
    <section class="finance">
      <div>
        <div class="container">
          <div class="container-left">
            <div class="title">
              Wrapped Finance
              <div class="image">
                <img src="../assets/wrapfi-icon.svg" /> 
              </div>
            </div>
            <div class="title2">
              WRAP<span class="title21">FI</span>
            </div>
            <div class="desc">
              The First Specialized DEX for Interest-Bearing Tokens Powered by TMM Algorithm.
            </div>
          </div>

          <div class="container-right">
            <div class="image">
              <img src="../assets/finance.svg" /> 
            </div>
          </div>

        </div>

        <div class="image">
          <img src="../assets/finance-token.png" /> 
        </div>
      </div>
    </section>

    <section class="wrapped-finance">
      <div>
        <div class="container">
          <div class="container-left">
            <div class="title">
              Wrapped Finance
            </div>

            <div class="content">
              <div class="content-item">
                <div class="item-title">
                  FIRST, WE START WITH WETH
                </div>
                <div class="item-desc">
                  Wrapped Finance originated from WETH(Wrapped ETH), to conform to its own ERC-20 STANDARD
                </div>
              </div>

              <div class="content-item content-item-2">
                <div class="item-title">
                  SECOND, WE HAVE WRAPPED BTC, WRAPPED ALTS
                </div>
                <div class="item-desc">
                  Ethereum has become the most popular SMART CONTRACT PLATFORM, assets on other chains are wrapped to join in the Ethereum DeFi ecosystem. 
                </div>
              </div>

              <div class="content-item content-item-3">
                <div class="item-title">
                  THIRD, WE HAVE WARRPED DERIVATIVES
                </div>
                <div class="item-desc">
                  DeFi brings DERIVATIVES into public, wrapped interest-bearing tokens like cToken, rToken, etc, are emerging.
                </div>
              </div>
            </div>
            
          </div>

          <div class="container-right">
            <div class="image">
              <img src="../assets/wrapped-finance.svg" /> 
            </div>
          </div>

        </div>
      </div>
    </section>


    <section class="ibt">
      <div>
        <div class="container">
          <div class="container-left">
            <div class="title">
              WrapFi Specializes on Wrapped Finance, focusing on building the best DEX for <span class="green-color">I</span>nterest-<span class="green-color">B</span>earing <span class="green-color">T</span>okens (IBT) with Tracking Market Maker (TMM) Algorithm.
            </div>

            <div class="desc desc1">
              Price discovery of IBT on the current AMMs (even PMMs) is not well supportive, Trading pairs like cToken/Token, rToken/Token etc, can not capture the dynamic value-adding when it is generating yields in a certain period of time.
            </div>

            <div class="desc desc2">
              WrapFi creates an innovative Tracking Market Maker (TMM) algorithm to provide the most stable price and sufficient liquidity for Interest-bearing tokens.
            </div>
            
          </div>

          <div class="container-right">
            <div class="image">
              <img src="../assets/ibt.svg" /> 
            </div>
          </div>

        </div>
      </div>
    </section>


    <section class="amm-pmm">
      <div>
        <div class="container">

          <div class="image-amm-1">
            <img src="../assets/amm-pmm-1.svg" /> 
          </div>

          <div class="image-amm-2">
            <img src="../assets/amm-pmm-2.svg" /> 
          </div>

          <div class="title title-1">
            AMM / PMM
          </div>
          <div class="title title-2">
            Limitations & Restrictions
          </div>

          <div class="content">
            <div class="item">
              <div class="image image-1">
                <img src="../assets/limitations-1.svg" /> 
              </div>
              <div class="desc">
                Generalized Solution not friendly for trading IBT assets
              </div>
            </div>

            <div class="item">
              <div class="image image-2">
                <img src="../assets/limitations-2.svg" /> 
              </div>
              <div class="desc">
                Huge Price Gap between DEX and real exchange rate
              </div>
            </div>

            <div class="item">
              <div class="image image-3">
                <img src="../assets/limitations-3.svg" /> 
              </div>
              <div class="desc">
                Low Capital Efficiency for IBT holders
              </div>
            </div>

            <div class="item item-4">
              <div class="image image-4">
                <img src="../assets/limitations-4.svg" /> 
              </div>
              <div class="desc">
                Low Liquidity limits the further adoption of IBT
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>


    <section class="features">
      <div>
        <div class="container">

          <div class="title">
            <div class="image">
              <img src="../assets/wrapfi-icon.svg" /> 
            </div>
            <div class="title-content">
              <span class="wrapfi">WrapFi TMM DEX</span><span class="wrapfi-features">Features</span>
            </div>
          </div>

          <div class="features-line"></div>

          <div class="content content-1">
            <div class="item item-11">
              <div class="image image-1">
                <img src="../assets/features-1.svg" /> 
              </div>
              <div class="title title-1">
                Interest-Bearing Token
              </div>
              <div class="desc">
                WrapFi is specially built for trading Interest-Bearing Tokens, like cToken, rToken, ibToken , and they can be traded on WrapFi more friendly.
              </div>
            </div>

            <div class="item item-12">
              <div class="image image-2">
                <img src="../assets/features-2.svg" /> 
              </div>
              <div class="title title-2">
                Price Recorrection
              </div>
              <div class="desc">
                Unique TMM algorithm creates a dynamic price recorrection mechanism betweem DEX and real exchange rate.
              </div>
            </div>
          </div>

          <div class="content content-2">
            <div class="item item-21">
              <div class="image image-3">
                <img src="../assets/features-3.svg" /> 
              </div>
              <div class="title title-3">
                Cross-Chain Rate Oracle
              </div>
              <div class="desc">
                Cross-chain rate oracle ensures a better pegged price, it fetches the exchange rate data from the different original chains(where the IBT is issued) and updates to the WrapFi.
              </div>
            </div>

            <div class="item item-22">
              <div class="image image-4">
                <img src="../assets/features-4.svg" /> 
              </div>
              <div class="title title-4">
                Liquidity Incentive
              </div>
              <div class="desc">
                WrapFi Incentive program specially designed for IBT Pools, tokens with interets can join the pool to share the fee of earning.
              </div>
            </div>
          </div>

          <div class="content content-3">
            <div class="item item-31">
              <div class="image image-5">
                <img src="../assets/features-5.svg" /> 
              </div>
              <div class="title title-5">
                Ethereum&Polkadot Compatible
              </div>
              <div class="desc">
                WrapFi will be multi-chain deployed like on Ethereum, Polkadot, StaFi and BSC, etc, WrapFi could provide DEX services for the IBT assets on different chains.
              </div>
            </div>

            <div class="item item-32">
              <div class="image image-6">
                <img src="../assets/features-6.svg" /> 
              </div>
              <div class="title title-6">
                Special UI/UX for IBT
              </div>
              <div class="desc">
                Special and friendly UI/UX for IBT is needed, like update of exchange rate, price recorrections, etc.
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>


    <section class="tech">
      <div>
        <div class="container">

          <div class="container-left">

            <div class="image">
              <img src="../assets/tech-doc.svg" /> 
            </div>
            <div class="title">
              Tech/Design/Architecture
            </div>
            <div class="desc">
              Read WrapFi documentation about how we define interest-bearing token swap, and read tech, design and architecture about WrapFi solutions. 
            </div>

            <div class="btn" @click="jumpTo('documentation')">
              Documentation
            </div>

          </div>

          <div class="container-right">
            <div class="image">
              <img src="../assets/tech-code.svg" /> 
            </div>
          </div>

        </div>

        <div class="tech-line"></div>
        
      </div>
    </section>


     <section class="roadmap">
      <div>
        <div class="container">

          <div class="title">
            Roadmap
          </div>
          <div class="desc">
            To be specificate
          </div>
          
          <div class="content">
            <div class="item">
              <div class="title">
                <div class="image">
                  <img src="../assets/roadmap-q.svg" /> 
                </div>
                <div class="title-content">
                  2021 Q1
                </div>
              </div>
              <div class="desc desc1">
                Brainstorming & Ideation
              </div>
              <div class="desc desc2">
                Solution & Design
              </div>
            </div>

            <div class="item">
              <div class="title">
                <div class="image">
                  <img src="../assets/roadmap-q.svg" /> 
                </div>
                <div class="title-content">
                  2021 Q2
                </div>
              </div>
              <div class="desc desc1">
                Paper work
              </div>
              <div class="desc desc2">
                Website & MVP Building
              </div>
            </div>

            <div class="item">
              <div class="title">
                <div class="image">
                  <img src="../assets/roadmap-q.svg" /> 
                </div>
                <div class="title-content">
                  2021 Q3
                </div>
              </div>
              <div class="desc desc1">
                Testnet on Ethereum & Substrate
              </div>
              <div class="desc desc2">
                Extension on Layer2 & Testing 
              </div>
            </div>


            <div class="item">
              <div class="title">
                <div class="image">
                  <img src="../assets/roadmap-q.svg" /> 
                </div>
                <div class="title-content">
                  2021 Q4
                </div>
              </div>
              <div class="desc desc1">
                Testing & Audit & Launch
              </div>
              <div class="desc desc2">
                More to be explored
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: 'home',
  data() {
    return {
      connected: true,
    }
  },
  created() {
    
  },
  methods: {
    jumpTo(target) {
      if (target == 'documentation') {
        window.open('https://docs.wrapfi.io');
      } else if (target == 'fair-launch-phase1') {
        window.open('https://drop.wrapfi.io/phase1');
      } else if (target == 'fair-launch-phase2') {
        window.open('https://drop.wrapfi.io/phase2');
      } else if (target == 'fair-launch') {
        window.open('https://docs.wrapfi.io/genesis-fair-launch-plan');
      }
    },
  }
}
</script>

<style lang="scss">
@import "../mixin.scss";

.home {

  .head {
    background-color: #03C19B;
    border: 5px solid #525252;
    height: 387px;

    .container {
      padding-left: 128px;
      display: flex;

      .container-left {
        padding-top: 90px;
        flex: 1;

        .title {
          font-size: 50px;
          font-weight: bold;
          color: #232323;
          width: 766px;

          .title11 {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .operations {
          display: flex;
          margin-top: 52px;

          .phase1 {
            position: relative;

            .live {
              position: absolute;
              top: -11px;
              left: 146px;
              color: #FF1200;
              font-size: 10px;
              font-weight: bold;
              font-style: italic;
            }
          }

          .phase2 {
            position: relative;

            .live {
              position: absolute;
              top: -11px;
              left: 176px;
              color: #FF1200;
              font-size: 10px;
              font-weight: bold;
              font-style: italic;
            }
          }

          .phase-btn {
            cursor: pointer;
            width: 171px;
            height: 39px;
            line-height: 39px;
            border-radius: 3px;
            background-color: #FFE09B;
            color: #393939;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
          }

          .phase2-btn {
            margin-left: 30px;
          }
        }

      }

      .container-right {
        margin-right: 70px;
        .image {
          margin-top: -5px;
          width: 241px;
          height: 398px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .finance {
    padding-top: 45px;

    .image {
      width: 1218px;
      height: 172px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .container {
      padding-left: 80px;
      display: flex;

      .container-left {
        flex: 1;

        .title {
          position: relative;
          font-size: 50px;
          font-weight: bold;
          color: #525252;

          .image {
            position: absolute;
            width: 18px;
            height: 18px;
            top: -48%;
            left: 58%;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        

        .title2 {
          margin-top: 50px;
          font-size: 70px;
          font-weight: bold;
          color: #02C09A;

          .title21 {
            color: #FDC839;
          }
        }

        .desc {
          margin-top: 56px;
          width: 550px;
          font-size: 28px;
          font-weight: bold;
          color: #757575;
        }
      }

      .container-right {
        margin-right: 80px;
        .image {
          margin-top: -10px;
          width: 400px;
          height: 400px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }


  .wrapped-finance {
    background-color: #fff;
    padding-left: 80px;
    padding-top: 58px;
    padding-bottom: 141px;

    .container {
      display: flex;

      .container-left {
        flex: 1;

        .title {
          font-size: 40px;
          font-weight: bold;
          color: #252525;
        }

        .content {
          margin-top: 53px;

          .content-item {

            .item-title {
              font-size: 20px;
              font-weight: bold;
              color: #252525;
            }

            .item-desc {
              margin-top: 10px;
              width: 620px;
              font-size: 20px;
              color: #818181;
            }
          }

          .content-item-2 {
            margin-top: 70px;
          }

          .content-item-3 {
            margin-top: 46px;
          }
        }
      }

      .container-right {
        margin-right: 91px;
        margin-top: 120px;
        .image {
          width: 301px;
          height: 283px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }


  .ibt {
    // background-color: #fff;
    padding-left: 80px;
    padding-top: 82px;
    padding-bottom: 94px;

    .container {
      display: flex;

      .container-left {
        margin-top: 9px;
        flex: 1;

        .title {
          width: 712px;
          font-size: 30px;
          font-weight: bold;
          color: #252525;

          .green-color {
            color: #02C09A;
          }

          margin-bottom: 40px;
        }

        .desc {
          color: #818181;
          font-size: 20px;
          line-height: 40px;
          width: 677px;
        }

        .desc2 {
          margin-top: 40px;
        }
      }

      .container-right {
        margin-right: 80px;
        .image {
          width: 348px;
          height: 496px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }


  .amm-pmm {
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 90px;

    .container {

      position: relative;

      .image-amm-1 {
        position: absolute;
        margin-top: -70px;
        width: 116px;
        height: 232px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .image-amm-2 {
        position: absolute;
        right: 0;
        width: 156px;
        height: 232px;
        margin-top: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-size: 40px;
        font-weight: bold;
        color: #252525;
        text-align: center;
      }

      .title-2 {
        margin-bottom: 70px;
      }

      .content {
        display: flex;
        padding-left: 40px;
        padding-right: 40px;

        .item {
          padding-top: 21px;
          width: 263px;
          height: 119px;
          margin: 0 auto;

          .image {
            margin: 0 auto;
          }

          .image-1 {
            width: 73px;
            height: 44px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .image-2 {
            width: 140px;
            height: 45px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .image-3 {
            width: 68px;
            height: 45px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .image-4 {
            width: 68px;
            height: 70px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .desc {
            margin-top: 25px;
            font-size: 18px;
            color: #252525;
            text-align: center;
          }
        }

        .item-4 {
          padding-top: 0;
          height: 140px;
        }
      }
    }
  }


  .features {
    // background-color: #fff;
    padding-top: 50px;

    .container {

      .title {
        display: flex;
        justify-content: center;

        .image {
          width: 45px;
          height: 44px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .wrapfi {
          font-size: 40px;
          color: #525252;
          text-align: center;
          margin-left: 13px;
        }

        .wrapfi-features {
          font-size: 40px;
          font-weight: bold;
          color: #252525;
          text-align: center;
          margin-left: 10px;
        }
        
      }

      .features-line {
        position: absolute;
        left: 50%;
        margin-top: 47px;
        height: 1012px;
        border-left: 1px solid #DEDEDE;
      }

      .content {
        width: 1050px;
        margin: 0 auto;
        display: flex;
        border-bottom: 1px solid #DEDEDE;
        

        .item {
          width: 320px;
          margin: 0 auto;
          padding-bottom: 60px;

          .image {
            margin: 0 auto;
          }

          .image-1 {
            width: 268px;
            height: 74px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .image-2 {
            width: 199px;
            height: 69px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .image-3 {
            width: 201px;
            height: 69px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .image-4 {
            width: 146px;
            height: 142px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .image-5 {
            width: 195px;
            height: 80px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .image-6 {
            width: 155px;
            height: 64px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .title {
            font-size: 24px;
            font-weight: bold;
            color: #252525;
            text-align: center;
          }

          .title-1 {
            margin-top: 26px;
          }
          .title-2 {
            margin-top: 30px;
          }
          .title-3 {
            margin-top: 30px;
          }
          .title-4 {
            margin-top: 2px;
          }
          .title-5 {
            margin-top: 30px;
          }
          .title-6 {
            margin-top: 46px;
          }

          .desc {
            font-size: 16px;
            color: #818181;
            text-align: center;
            margin-top: 30px;
          }
        }

        .item-11 {
          padding-top: 80px;
        }
        .item-12 {
          padding-top: 80px;
        }

        .item-21 {
          padding-top: 68px;
        }

        .item-22 {
          padding-top: 28px;
          width: 340px;
        }

        .item-31 {
          padding-top: 60px;
          width: 374px;
        }

        .item-32 {
          padding-top: 60px;
        }

    
      }

      .content-3 {
        border-bottom: none;
      }

    }

  }

  .tech {
    background-color: #fff;
    padding-top: 67px;

    .container {
      padding-bottom: 67px;
      padding-left: 80px;
      display: flex;

      .container-left {
        flex: 1;

        .image {
          margin-top: 119px;
          width: 42px;
          height: 42px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          margin-top: 38px;
          font-size: 40px;
          font-weight: bold;
          color: #252525;
        }

        .desc {
          margin-top: 20px;
          width: 550px;
          font-size: 16px;
          color: #818181;
        }

        .btn {
          margin-top: 40px;
          cursor: pointer;
          font-style: italic;
          border: 1.111px solid #525252;
          width: 171px;
          height: 39px;
          line-height: 39px;
          color: #3B3D5C;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          border-radius: 3.33px;
        }
      }

      .container-right {
        .image {
          width: 640px;
          height: 565px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .tech-line {
      margin: 0 auto;
      width: 1050px;
      border-bottom: 1px solid #DEDEDE;
    }
  }

  .roadmap {
    // background-color: #fff;
    padding-top: 71px;
    padding-bottom: 121px;

    .container {
      padding-left: 80px;

      .title {
        font-size: 40px;
        color: #252525;
        font-weight: bold;
      }

      .desc {
        font-size: 16px;
        color: #818181;
      }

      .content {
        margin-top: 50px;
        display: flex;

        .item {
          flex: 1;

          .title {
            display: flex;

            .image {
              margin-top: -13px;
              width: 16px;
              height: 21px;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .title-content {
              margin-left: 10px;
              font-size: 24px;
              color: #252525;
              font-weight: bold;
            }
          }

          .desc {
            font-size: 16px;
            color: #818181;
          }

          .desc1 {
            margin-top: 18px;
          }

          .desc2 {
            margin-top: 10px;
          }
        }
      }
    }

  }


}
</style>
