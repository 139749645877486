<template>
  <div id="app" class="app" :style="{width: screenWidth}">
    <div class="nav">
      <div class="logo" @click="clickLogo">
        <img src="./assets/logo.svg" />
      </div>
      <div class="menus">
        <span 
          :class="{'active': active === 'about'}"
          class="menu"
          @click="selectElement('about')">About</span>
        <span 
          :class="{'active': active === 'feature'}"
          class="menu"
          @click="selectElement('feature')">Feature</span>
        <span
          :class="{'active': active === 'documentation'}"
          class="menu"
          @click="jumpTo('documentation')">Documentation</span>
        <span
          :class="{'active': active === 'roadmap'}"
          class="menu"
          @click="selectElement('roadmap')">Roadmap</span>
        <span class="menu selected">
          <span class="dropdown">
            <span class="dropbtn">Fair Launch</span>
            <div class="dropdown-content">
              <p class="phase-drop" @click="jumpTo('fair-launch-phase1')">Phase1 AirDrop</p>
              <p class="phase-drop-line"></p>
              <p class="phase-drop" @click="jumpTo('fair-launch-phase2')">Phase2 LPDrop</p>
            </div>
          </span>
        </span>
      </div>
    </div>
    <router-view></router-view>
    <component
    :is="footerBarComponent" />
  </div>
</template>
<script>
import FooterBar from './components/FooterBar';
import { Toast } from 'mint-ui';

export default {
  name: 'app',
  components: {
    FooterBar,
  },
  data() {
    return {
      active: '',
      footerBarComponent: 'FooterBar',
      screenWidth: '1280px'
    };
  },
  methods: {
    selectElement(name) {
      this.active = name;
      if (name == 'about') {
        window.scrollTo(0, 1080);
      } else if (name == 'feature') {
        window.scrollTo(0, 2900);
      } else if (name == 'roadmap') {
        window.scrollTo(0, 4600);
      }
    },
    clickLogo() {
      this.active = '';
      window.scrollTo(0, 0);
    },
    jumpTo(target) {
      if (target == 'documentation') {
        window.open('https://docs.wrapfi.io');
      } else if (target == 'fair-launch-phase1') {
        window.open('https://drop.wrapfi.io/phase1');
      } else if (target == 'fair-launch-phase2') {
        window.open('https://drop.wrapfi.io/phase2');
      }
    },
    getRequest() {
      let url = window.location.search;
      let theRequest = new Object();
      if (url.indexOf('?') != -1) {
        let str = url.substr(1);
        let strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
        }
      }
      return theRequest;
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      window.localStorage.setItem('lang', lang);
    },
  },
  created() {
    const self = this;
    const innerWidth = window.innerWidth;

    if (innerWidth <= 1280 && innerWidth >= 960) {
      this.screenWidth = (innerWidth - 10) + 'px';
    }
  }
}
</script>

<style lang="scss">

.nav {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 32px;
  padding-bottom: 16px;
  
  display: flex;

  .logo {
    cursor: pointer;
    height: 15px;
    img {
      height: 100%;
    }
  }

  .menus {
    flex: 1;
    text-align: right;

    .menu {
      cursor: pointer;
      font-size: 16px;

      color: #242424;
      text-decoration: none;
      padding: 10px 20px;
      position: relative;

      .el-dropdown {
        font-size: 16px;
        color: #fff;

        .rbridge-beta {
          color: #00F3AB;
        }
      }

      &.active {
        font-weight: bold;
        &::after {
          content: " ";
          height: 2px;
          background-color: #00F3AB;
          position: absolute;
          bottom: 0;
          left: 16px;
          right: 16px;
        }
      }

      .dropbtn {
        background-color: #6FEDA5;
        border-radius: 3px;
        color: #3B3D5C;
        font-weight: bold;
        font-style: italic;
        padding: 8px 30px;
        width: 144px;
      }

      .dropdown {
        position: relative;
        display: inline-block;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #fff;
        min-width: 100px;
        color: #23292F;
        font-size: 12px;
        text-align: center;
        margin-top: 8px;
        margin-left: 18px;
        border-radius: 1px;
      }

      .phase-drop {
        padding-left: 16px;
        padding-right: 16px;
      }

      .phase-drop-line {
        border-bottom: 0.5px solid #C2C2C2;
      }

      // .dropdown-content a {
      //     color: #23292F;
      //     padding: 12px 16px;
      //     text-decoration: none;
      //     display: block;
      // }

      // .dropdown-content a:hover {
      //   background-color: #3e8e41;
      // }

      .dropdown:hover .dropdown-content {
        display: block;
      }

      .dropdown:hover .dropbtn {
        background-color: #3e8e41;
      }
    }
  }
}
#app {
  font-family: Helvetica, "Avenir", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FBFBFB;
  // width: 1280px;
  margin: 0 auto;
}
</style>
